import React from 'react';
import { BsTwitterX, BsLinkedin } from 'react-icons/bs';

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a href="https://twitter.com/robertburke2x" target="_blank"><BsTwitterX /></a>
    </div>
    <div>
      <a href="https://www.linkedin.com/in/robertanthonyburke/" target="_blank"><BsLinkedin /></a>
    </div>
  </div>
);

export default SocialMedia;